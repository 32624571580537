import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Pagination from "../../../services/Pagination";
import { Apiurl } from "../../../services/apiRoute";
import { getMethod, postMethod } from "../../../services/base_service";
import { showAlert } from "../../../services/alert_service";

const ReferralRequests = () => {
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);

  // get referral request list
  const getReferralRequests = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.referralRequest}`, {
        page: currentPage,
        limit: 8,
      });
      setCount(response.data.total);
      setIsLoading(false);
      setRequests(response.data.data);
      setTotalPages(response.data.total_page);
    } catch (error) {
      setIsLoading(false);
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  const handleUpdateReferral = async (e, id) => {
    try {
      setIsLoading(true);
      const response = await postMethod(`${Apiurl.referral}/${id}`);

      showAlert(response.data.message, "success");
      setIsLoading(false);
      getReferralRequests();
    } catch (error) {
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    getReferralRequests();
  }, [currentPage]);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>
                  Referral Requests:{" "}
                  <span style={{ color: "red" }}>{count}</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : requests.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>User Name</th>
                          <th>Email</th>
                          <th>Approve</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requests.map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{i + 1}</td>
                              <td>{list?.user?.user_name}</td>
                              <td>{list?.user?.email}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  onClick={(e) =>
                                    handleUpdateReferral(e, list.id)
                                  }
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralRequests;
