import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import Pagination from "../../../services/Pagination";
import { Apiurl } from "../../../services/apiRoute";
import {
  getMethod,
  postMethod,
  putMethod,
} from "../../../services/base_service";
import { showAlert } from "../../../services/alert_service";
import { Modal, ModalHeader } from "reactstrap";

const CurrencyRequests = () => {
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [modal, setModal] = useState(false);
  const [count, setCount] = useState(0);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  // get password request list
  const getCurrencyRequests = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.currencyRequest}`, {
        page: currentPage,
        limit: 8,
      });
      setIsLoading(false);
      setCount(response.data.total);
      setRequests(response.data.data);
      setTotalPages(response.data.total_page);
      setCurrencies(response.data?.data[0]?.admin_currencies);
    } catch (error) {
      setIsLoading(false);
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const setEditModal = (terminalId) => {
    setModal(true);
    const selected = requests.find((item) => item.terminal_id === terminalId);
    setSelected(selected);
  };

  const handleUpdateCurrency = async (data) => {
    try {
      let formData = {};
      formData.user_id = selected.user_id;
      formData.terminal_id = selected.terminal_id;
      formData.status = data.status;
      let currency = [];
      currencies.map((list) => {
        const abc = {
          id: list.id,
          currencies_id: list.currencies_id,
          count: list.count,
        };
        return currency.push(abc);
      });
      formData.currency = currency;

      setIsLoading(true);
      const response = await putMethod(Apiurl.currencyUpdate, formData);
      getCurrencyRequests();
      setModal(false);
      showAlert(response.data.data.message, "success");
      setIsLoading(false);
    } catch (error) {
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  useEffect(() => {
    getCurrencyRequests();
  }, [currentPage]);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Currency</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleUpdateCurrency)}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Status
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          name="role_id"
                          className="form-control input-height"
                          style={{ width: "300px", marginLeft: "8px" }}
                          {...register("status", {
                            required: "Status is Required.",
                          })}
                        >
                          <option value="" disabled selected>
                            Select Status...
                          </option>
                          <option key="approve" value="approved">
                            Approve
                          </option>
                          <option key="reject" value="reject">
                            Reject
                          </option>
                        </select>
                      </div>
                      {errors.status && (
                        <p style={{ color: "red", fontSize: "13px" }}>
                          {errors.status.message}
                        </p>
                      )}
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "20px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                              reset();
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>
                  Currency Requests:{" "}
                  <span style={{ color: "red" }}>{count}</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : requests.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Account Number</th>
                          <th>Server Name</th>
                          {currencies.map((list, i) => (
                            <th key={i}>{list?.currency?.name}</th>
                          ))}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requests.map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{i + 1}</td>
                              <td>{list?.terminal_id}</td>
                              <td>{list?.t_pc?.name}</td>
                              {currencies.map((list, i) => (
                                <td key={i}>{list?.count}</td>
                              ))}
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-default btn-sm"
                                  title="Edit"
                                  onClick={() => setEditModal(list.terminal_id)}
                                >
                                  <i className="fa fa-pencil"></i>
                                </button>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyRequests;
