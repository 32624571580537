import React from "react";
import { Header } from "../utils/NameString";
import "../../css/sidebar.css";
import { Cookies } from "react-cookie";

const Navbar = () => {
  const cookies = new Cookies();
  const handleLogout = async () => {
    await cookies.remove("token")
    await localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <div>
      <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-left">
            <div className="navbar-btn">
              <img
                src={Header.Logo_image}
                alt="hospital Logo"
                className="img-fluid logo"
              />
              <button type="button" className="btn-toggle-offcanvas">
                <i className="fa fa-align-left"></i>
              </button>
            </div>
          </div>
          <div className="navbar-right">
            <div id="navbar-menu">
              <ul className="nav navbar-nav">
                <li>
                  <div className="icon-menu" onClick={handleLogout}>
                    <i className="fa fa-power-off"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
