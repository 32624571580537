import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import { showAlert } from '../../../services/alert_service';
import { postMethod } from '../../../services/base_service';
import { useNavigate } from 'react-router-dom';
import { Apiurl } from '../../../services/apiRoute';

const AddUser = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [formValue, setFormValue] = useState({
        sponsor_code: "",
        user_name: "",
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue({ ...formValue, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    useEffect(() => {
        localStorage.removeItem("verifyemail")
    })

    const validationRules = {
        // sponsor_code: "Please enter sponsor code ",
        user_name: "Name is required",
        email: "Email is required",
        password: "Password is required",
    };

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};
        Object.entries(validationRules).forEach(([key, message]) => {
            if (!formValue[key]) {
                newErrors[key] = message;
                formIsValid = false;
            }
        });
        const emailRegex = /^[\w\+\-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (formValue.email && !emailRegex.test(formValue.email)) {
            newErrors.email = "Enter a valid email address.";
            formIsValid = false;
        }
        const passwordRegex =
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
        if (formValue.password && !passwordRegex.test(formValue.password)) {
            newErrors.password =
                "Password must be at least 8 characters long and contain at least one letter, one number, and one special character.";
            formIsValid = false;
        }
        setErrors(newErrors);
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (validateForm()) {
                setIsLoading(true);
                const data = formValue.email
                const response = await postMethod(Apiurl.signup, {email: data});
                if (response.data.status === 403) {
                    showAlert(response.data.message, 'error')
                    setIsLoading(false);
                } else {
                    localStorage.setItem("verifyemail", formValue.email);
                    localStorage.setItem("sponsercode", formValue.sponsor_code);
                    localStorage.setItem("username", formValue.user_name);
                    localStorage.setItem("password", formValue.password);
                    setIsLoading(false);
                    setFormValue({
                        sponsor_code: "",
                        user_name: "",
                        email: "",
                        password: "",
                    });
                    setErrors({});
                    showAlert(response.data.message, "success");
                    navigate("/userverification");
                }
            }
        } catch (error) {
            console.log("Error:", error);
            showAlert("Error in registration due to server error", "error")
            setIsLoading(false);
      
        }
    };

    return (
        <div>
            <Navbar />
            <div id="main-content">
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <h1>Add New User</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group row">
                                            {isLoading ? <span className="loader"></span> : ""}
                                            <label className="col-sm-2 col-form-label">
                                                Sponser Code
                                                <span className="text-danger" style={{ margin: "4px" }}>
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-md-6 col-sm-10">
                                                <input
                                                    type="text"
                                                    name="sponsor_code"
                                                    value={formValue.sponsor_code}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    id="inputEmail3"
                                                    placeholder="Enter Sponser Code"
                                                />
                                                {/* {errors.name && (
                                                    <p
                                                        style={{
                                                            color: "red",
                                                            marginBottom: "0px",
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        {errors.name}
                                                    </p>
                                                )} */}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">
                                                User Name
                                                <span className="text-danger" style={{ margin: "4px" }}>
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-md-6 col-sm-10">
                                                <input
                                                    type="text"

                                                    className="form-control"
                                                    name="user_name"
                                                    value={formValue.user_name}
                                                    onChange={handleChange}
                                                    id="inputEmail2"
                                                    placeholder="Enter User Name"
                                                />
                                                {errors.user_name && (
                                                    <p
                                                        style={{
                                                            color: "red",
                                                            marginBottom: "0px",
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        {errors.user_name}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">
                                            Email
                                                <span className="text-danger" style={{ margin: "4px" }}>
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-md-6 col-sm-10">
                                                <input
                                                   type="email"
                                                    name="email"
                                                    value={formValue.email}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter your email address"
                                                    id="inputEmail2"
                                                />
                                                {errors.email && (
                                                    <p
                                                        style={{
                                                            color: "red",
                                                            marginBottom: "0px",
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        {errors.email}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">
                                            Password
                                                <span className="text-danger" style={{ margin: "4px" }}>
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-md-6 col-sm-10">
                                                <input
                                                  type="password"
                                                    name="password"
                                                    value={formValue.password}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter password"
                                                    id="inputEmail2"
                                                />
                                                {errors.password && (
                                                    <p
                                                        style={{
                                                            color: "red",
                                                            marginBottom: "0px",
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        {errors.password}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label"></label>
                                            <div className="col-md-6 col-sm-10">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success btn_bg_color"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddUser;
