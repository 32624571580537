import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/Login";
import AccessControl from "./services/AccessControl";
import AddTPC from "./components/pages/tpc/AddTPC";
import TPCs from "./components/pages/tpc/TPCs";
import AddCurrency from "./components/pages/currency/AddCurrency";
import CurrencyList from "./components/pages/currency/CurrencyList";
import AccountRequests from "./components/pages/request/AccountRequests";
import PasswordRequests from "./components/pages/request/PasswordRequests";
import ReferralRequests from "./components/pages/request/ReferralRequests";
import Accountlist from "./components/pages/request/Accountlist";
import Currencyhistry from "./components/pages/Histry/Currencieshistry";
import Accounthistry from "./components/pages/Histry/Accounthistry";
import Users from "./components/pages/Users/Users";
import CurrencyRequests from "./components/pages/request/CurrencyRequests";
import SendMessage from "./components/pages/SendMessage";
import AccountStartStopRequests from "./components/pages/request/AccountStartStopRequest";
import AddUser from "./components/pages/Users/AddUser";
import UserVerification from "./components/pages/Users/UserVerification";

function App() {
  // const isLoggedIn = localStorage.getItem("token");
  // const verifyemail = localStorage.getItem("verifyemail");

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route element={<AccessControl />}>
            <Route path="/add-tpc" element={<AddTPC />} />
            <Route path="/tpcs" element={<TPCs />} />
            <Route path="/users" element={<Users />} />
            <Route path="/adduser" element={<AddUser />} />
            <Route path="/userverification" element={<UserVerification />}/>
            <Route path="/add-currency" element={<AddCurrency />} />
            <Route path="/currencies" element={<CurrencyList />} />
            <Route path="/" element={<AccountRequests />} />
            <Route path="/password-requests" element={<PasswordRequests />} />
            <Route path="/currency-requests" element={<CurrencyRequests />} />
            <Route path="/referral-requests" element={<ReferralRequests />} />
            <Route path="/Accounthistry" element={<Accounthistry/>} />
            <Route path="/currencieshistry" element={<Currencyhistry />} />
            <Route path="/send-message" element={<SendMessage />} />
            <Route path="/accountlist" element={<Accountlist />} />
            <Route
              path="/account-start-stop"
              element={<AccountStartStopRequests />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
