export const Apiurl = {
  // routePath: "http://192.168.28.6:3011/api/v1",
  routePath: "https://api.heartyrobo.com/api/v1",

  // imageRoutePath: "http://192.168.28.6:3011",
  imageRoutePath:"https://api.heartyrobo.com",
  login: "/admin/login",
  t_pc: "/t_pc/list",
  currency: "/currency",
  currencyUpdate: "/admin/currency/update",
  accountRequest: "/admin/account_request/list",
  accountStopRequest: "/admin/account/stop-requests",
  manageStatus: "/admin/account/manage-status",
  passwordRequest: "/admin/password_request/list",
  referralRequest: "/admin/referral_request/list",
  currencyRequest: "/admin/currency_request/list",
  password: "/admin/update/password",
  account: "/admin/account/update",
  referral: "/admin/update/referral",
  signup: "/user/signup",
  emailVerify: "/otp/verify",
  resendOtp: "/otp/resend",
  addAccount: "/account/add",
  updateCurrency: "/add_and_update/currency",
  updateCurrencyList: "/currencyList",
  generate_referral_code: "/user/generate_referral_code",
  fcm_token: "/admin/fcm_token",
  user_list: "/admin/user/list",
  send_message: "/send/user/notification",
  account_list:"/admin/account_history/list",
  Currencyhistry:"/admin/currency_history/list",
  user_account_list:"/admin/account/list",
  update_password:"/admin/update/password",
};
