import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import Pagination from "../../../services/Pagination";
import { Modal, ModalHeader } from "reactstrap";
import { Apiurl } from "../../../services/apiRoute";
import { getMethod, postMethod } from "../../../services/base_service";
import { showAlert } from "../../../services/alert_service";

const PasswordRequests = () => {
  const {
    register,
    handleSubmit,

    reset,
    formState: { errors },
  } = useForm();
  const [count, setCount] = useState(0);
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(0);

  const supportedFormats = [
    "image/apng",
    "image/avif",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/webp",
  ];

  // get password request list
  const getPasswordRequests = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.passwordRequest}`, {
        page: currentPage,
        limit: 8,
        type: "",
      });
      setCount(response.data.total);
      setIsLoading(false);
      setRequests(response.data.data);
      setTotalPages(response.data.total_page);
    } catch (error) {
      setIsLoading(false);
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const setEditModal = (id) => {
    setModal(true);
    const selected = requests.find((item) => item.id === id);
    setId(selected.id);
  };

  const handleUpdatePassword = async (data) => {
    try {
      const formData = new FormData();
      formData.append("upload_image", data.upload_image[0]);
      setIsLoading(true);
      const response = await postMethod(`${Apiurl.password}/${id}`, formData);
      showAlert(response.data.message, "success");
      setIsLoading(false);
      getPasswordRequests();
      setModal(false);
    } catch (error) {
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  useEffect(() => {
    getPasswordRequests();
  }, [currentPage]);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Password Request</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleUpdatePassword)}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Image
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="file"
                            {...register("upload_image", {
                              required: "Image is Required.",
                              validate: {
                                isImage: (value) => {
                                  return (
                                    (value &&
                                      supportedFormats.includes(
                                        value[0]?.type
                                      )) ||
                                    "Only image files are allowed."
                                  );
                                },
                              },
                            })}
                            className="form-control"
                            name="upload_image"
                            placeholder="Choose Image"
                          />
                          {errors.upload_image && (
                            <p style={{ color: "red", fontSize: "13px" }}>
                              {errors.upload_image.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "20px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                              reset();
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>
                  Password Requests:{" "}
                  <span style={{ color: "red" }}>{count}</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : requests.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Account Number</th>
                          <th>T Password</th>
                          <th>Type</th>
                          <th>Terminal ROP</th>
                          <th>Username</th>
                          <th>Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requests.map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{i + 1}</td>
                              <td>{list?.terminal_id}</td>
                              <td>{list?.t_password}</td>
                              <td>{list?.type}</td>
                              <td>
                                {list?.terminal_read_only_password === null
                                  ? "-"
                                  : list?.terminal_read_only_password}
                              </td>
                              <td>{list?.user?.user_name}</td>
                              <td>{list?.user?.email}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-default btn-sm"
                                  title="Edit"
                                  onClick={() => setEditModal(list.id)}
                                >
                                  <i className="fa fa-pencil"></i>
                                </button>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRequests;
