import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Sidebar from '../components/pages/Sidebar';
import { Cookies } from 'react-cookie';

const AccessControl = () => {
  const cookies = new Cookies()
  // const isLoggedIn = localStorage.getItem("token");
  const isAdmin = cookies.get("token");
  const location = useLocation()
  const verifyEmailPage = location.pathname
  console.log('verifyEmailPage: ', verifyEmailPage)

  useEffect(() => {
    if (!isAdmin) {
      localStorage.clear();
      window.location.reload();
    }
  }, [isAdmin])

  return isAdmin ? (verifyEmailPage === "/userverification" ? <Outlet /> : <><Outlet /><Sidebar /></> ) : <Navigate to="/login" replace />
}

export default AccessControl;
