import axios from "axios";
import { Apiurl } from "./apiRoute";
import { Cookies } from "react-cookie";

export const handleApiError = (error) => {
  if (error.response) {
    const statusCode = error.response.status;
    if (statusCode === 404) {
      return {
        error: "User Not Found",
        message: "User not found. Please check your credentials.",
      };
    } else if (statusCode === 403) {
      return {
        error: "Record Already Exists",
        message: "Record already exists",
      };
    } else if (statusCode === 412) {
      return {
        error: "Validation Error",
        message: error.response.data.message,
      };
    } else {
      return { error: "Backend Error", message: error.response.data };
    }
  } else if (error.request) {
    return {
      error: "Network Error",
      message: "No response received from the server",
    };
  } else {
    return { error: "Client Error", message: error.message };
  }
};

export const getMethod = async (url, params = {}) => {
  const cookies = new Cookies()
  // const login_token = localStorage.getItem("token");
  const login_token = cookies.get("token");
  const headers = login_token ? { Authorization: login_token } : {};
  try {
    const response = await axios.get(Apiurl.routePath + url, {
      headers,
      params,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error.message;
    }
  }
};

export const postMethod = async (url, params) => {
  // const login_token = await localStorage.getItem("token");
  const cookies = new Cookies()
  const login_token = cookies.get("token");

  if (login_token !== null) {
    var header = { Authorization: login_token };
  }
  try {
    const Response = await axios.post(Apiurl.routePath + url, params, {
      headers: header,
    });
    return Response.data;
  } catch (error) {
    const handledError = handleApiError(error);
    return Promise.reject(handledError);
  }
};

export const deleteMethod = async (url) => {
  // const login_token = localStorage.getItem("token");
  const cookies = new Cookies()
  const login_token = cookies.get("token");

  if (login_token !== null) {
    var headers = { Authorization: login_token };
  }
  const response = await axios.delete(Apiurl.routePath + url, { headers });
  return response.data;
};

export const putMethod = async (url, params) => {
  // const login_token = localStorage.getItem("token");
  const cookies = new Cookies()
  const login_token = cookies.get("token");
  const headers = { Authorization: login_token };
  
  const data = await axios.put(Apiurl.routePath + url, params, { headers });
  return data;
};
