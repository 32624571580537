import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Navbar from "../Navbar";
import Pagination from "../../../services/Pagination";
import { Apiurl } from "../../../services/apiRoute";
import { getMethod, putMethod } from "../../../services/base_service";
import { showAlert } from "../../../services/alert_service";
import {
  getTokenData,
  onMessageListener,
  registerAndRequestPermission,
} from "../../../services/firebaseInit";

const AccountRequests = () => {
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const notify = () => toast(<ToastDisplay />);

  function ToastDisplay() {
    return (
      <div>
        <b>{notification?.title}</b>

        <p>{notification?.body}</p>
      </div>
    );
  }

  // get account request list
  const getAccountRequests = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.accountRequest}`, {
        page: currentPage,
        limit: 8,
      });
      setCount(response.data.total);
      setRequests(response.data.data);
      console.log("res.data in account request", response.data.data);
      setTotalPages(response.data.total_page);
      setIsLoading(false);
    } catch (error) {
      console.log("error:" + error);
      showAlert("Internal Server Error", "error");
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // const handleStopAccount = async (e, id, terminal_Id) => {
  //   e.preventDefault();
  //   try {
  //     const response = await putMethod(
  //       `${Apiurl.manageStatus}/${terminal_Id}/${id}`
  //     );
  //     console.log("res from stop acc", response.data);
  //     showAlert(response.data.data.message, "success");
  //     getAccountRequests();
  //   } catch (error) {
  //     console.log("error" + error);
  //     showAlert("Internal Server Error", "error");
  //   }
  // };

  // const handleStartAccount = async (e, terminalId) => {
  //   e.preventDefault();
  //   try {
  //     setIsLoading(true);
  //     console.log("apoirl", Apiurl.account);
  //     const response = await putMethod(`${Apiurl.account}/${terminalId}`);
  //     showAlert(response.data.data.message, "success");
  //     getAccountRequests();
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log("error" + error);
  //     showAlert("Internal Server Error", "error");
  //   }
  // };

  const handleChangeStatusAccount = async (e, terminal_id) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await putMethod(`${Apiurl.account}/${terminal_id}`, {
        status: "approved",
        account_status: "active",
      });
      console.log("response", response.data);
      showAlert(response.data.data.message, "success");
      getAccountRequests();
      setIsLoading(false);
    } catch (error) {
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  onMessageListener()
    ?.then((payload) => {
      console.log("payload===>", payload);
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      //
      getAccountRequests();
      localStorage.setItem(
        "reference_code",
        payload?.data?.reference_code ? payload?.data?.reference_code : ""
      );
      getAccountRequests();
    })
    ?.catch((err) => console.log("failed: ", err));

  async function handleRegistration() {
    const registration = await registerAndRequestPermission();
    if (registration) {
      const token = await getTokenData(registration);
      console.log("FCM token:=> ", token);
      if (token !== null) {
        sendFCMToken(token);
      }
    }
  }

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  useEffect(() => {
    handleRegistration();
  }, []);

  useEffect(() => {
    getAccountRequests();
  }, [currentPage]);

  const sendFCMToken = async (token) => {
    try {
      const response = await putMethod(Apiurl.fcm_token, {
        firebase_token: token,
      });
    } catch (error) {
      // showAlert(error.meaasge, "error");
      console.log("error in fcm token:", error)
    }
  };

  return (
    <div>
     <Toaster />
      <Navbar />
      <div id="main-content">
        {/* <ToastDisplay /> */}
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>
                  Account Requests:{" "}
                  <span style={{ color: "red" }}>{count}</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : requests.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Account Number</th>
                          <th>Requested</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requests.map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{i + 1}</td>
                              <td>{list?.terminal_id}</td>
                              <td>
                                {list?.account_status === "deactivate"
                                  ? "start"
                                  : "stop"}
                              </td>
                              {/* <td>
                                <button
                                  type="button"
                                  className="btn btn-default btn-sm"
                                  title="Edit"
                                  onClick={() => setEditModal(list.terminal_id)}
                                >
                                  <i className="fa fa-pencil"></i>
                                </button>
                              </td> */}
                              <td>
                                <input
                                  type="checkbox"
                                  onClick={(e) =>
                                    handleChangeStatusAccount(
                                      e,
                                      list.terminal_id
                                    )
                                  }
                                  // list?.account_status === "deactivate"
                                  // ? handleStartAccount(e, list.terminal_id)
                                  // : handleStopAccount(
                                  //     e,
                                  //     list.id,
                                  //     list?.terminal_id
                                  //   )
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountRequests;
