import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { getMethod, postMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import Pagination from "../../../services/Pagination";
import debounce from "lodash/debounce";
import "../../../css/sidebar.css";
import { showAlert } from "../../../services/alert_service";
import { Link } from "react-router-dom";
import { Modal, ModalHeader } from "reactstrap";

const Users = () => {
  const [users, setUsers] = useState([]);
  console.log('users: ', users)
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [AccModal, setAccModal] = useState(false);
  const [tpc, setTpc] = useState([]);
  const [userId, setUserId] = useState();
  const [formValue, setFormValue] = useState({
    terminal_id: 0,
    t_password: "",
    t_pc_id: 0,
  });

  const getUsers = async () => {
    setIsLoading(true);
    await getMethod(`${Apiurl.user_list}?page=${currentPage}&limit=10`)
      .then((res) => {
        setTotalPages(res.data.total_page);
        setUsers(res.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error" + error);
        showAlert("Error while fetching data", "error");
        setIsLoading(false);
      });
  };

  const getTpc = async () => {
    await getMethod(Apiurl.t_pc, {
      page: currentPage,
      limit: 50,
      search: searchQuery,
    })
      .then((res) => {
        setTpc(res.data.data);
      })
      .catch((error) => {
        console.log("Error" + error);
        showAlert("Error while fetching data", "error");
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const setAddAccModal = (userId) => {
    getTpc()
    setAccModal(true);
    setFormValue({});
    setUserId(userId);
  }

  const handleAccChange = (e) => {
    const { name, value } = e.target;
    const intValue = ["terminal_id", "t_pc_id"].includes(name)
      ? parseInt(value, 10)
      : value;
    setFormValue({ ...formValue, [name]: intValue });
    setErrors({ ...errors, [name]: "" });
  };

  const validationRules = {
    terminal_id: "Account no is required",
    t_password: "Password is required",
    t_pc_id: "Server name is required",
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};
    Object.entries(validationRules).forEach(([key, message]) => {
      if (!formValue[key]) {
        newErrors[key] = message;
        formIsValid = false;
      }
    });
    setErrors(newErrors);
    return formIsValid;
  };

  const handleAccSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        const data={...formValue, user_id:userId, isAdmin:true};
        console.log('data: ', data)
        const response = await postMethod(Apiurl.addAccount, data);
        if (response.data.status === 403) {
          showAlert(response.data.message, "error");
        } else {
          showAlert(response.data.message, "success");
        }
        setFormValue({
          terminal_id: 0,
          t_password: "",
          t_pc_id: 0,
        });
        setErrors({});
        setAccModal(!AccModal);
      }
    } catch (err) {
      console.log("error" + err);
      showAlert("Internal Server Error", "error");
    }
  };

  const delayedSearch = debounce(() => {
    getUsers();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  return (
    <div>
      <Navbar />
      <Modal isOpen={AccModal} toggle={() => setAccModal(!AccModal)}>
        <ModalHeader toggle={() => setAccModal(!AccModal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>ADD ACCOUNT</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleAccSubmit}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Account_No.
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="number"
                            value={formValue.terminal_id}
                            name="terminal_id"
                            className="form-control"
                            onChange={handleAccChange}
                            style={{ width: "300px" }}
                            placeholder="Enter Account Number"
                          />
                        </div>
                      </div>
                      {errors.terminal_id && (
                        <p className="useredit_err">{errors.terminal_id}</p>
                      )}

                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          T_Password
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            value={formValue.t_password}
                            name="t_password"
                            onChange={handleAccChange}
                            className="form-control"
                            style={{ width: "300px" }}
                            placeholder="Enter T Password"
                          />
                        </div>
                      </div>
                      {errors.t_password && (
                        <p className="useredit_err">{errors.t_password}</p>
                      )}
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Server_Name
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          name="t_pc_id"
                          type="text"
                          value={formValue.t_pc_id}
                          onChange={handleAccChange}
                          className="form-control input-height"
                          style={{ width: "300px", marginLeft: "8px" }}
                        >
                          <option value="">Select tpc...</option>
                          {tpc.map((tpc, i) => (
                            <option key={i} value={tpc.id}>
                              {tpc.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.t_pc_id && (
                        <p className="useredit_err">{errors.t_pc_id}</p>
                      )}

                      <div
                        className="form-group row"
                        style={{ marginTop: "10px" }}
                      >
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "30px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setAccModal(false);
                              setErrors({});
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>User List</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="mb-3 mb-xl-0">
                    <Link to="/adduser">
                      <span className="btn btn-dark">Add User</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : users.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Sponsor Code</th>
                          <th>User Name</th>
                          <th>Email</th>
                          <th>Reference Code</th>
                          <th>Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                {list?.sponsor_code != null
                                  ? list?.sponsor_code
                                  : "-"}
                              </td>
                              <td>
                                {list?.user_name != null
                                  ? list?.user_name
                                  : "-"}
                              </td>
                              <td>{list?.email != null ? list?.email : "-"}</td>
                              <td>
                                {list?.reference_code != null
                                  ? list?.reference_code
                                  : "-"}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-default btn-sm"
                                  title="Add Account"
                                  onClick={() => setAddAccModal(list.id)}
                                >
                                  <i className="fa fa-plus-square"></i>
                                </button>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
