import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Navbar from "../Navbar";
import Pagination from "../../../services/Pagination";
import { Apiurl } from "../../../services/apiRoute";
import { getMethod, putMethod } from "../../../services/base_service";
import { showAlert } from "../../../services/alert_service";
import {
  getTokenData,
  onMessageListener,
  registerAndRequestPermission,
} from "../../../services/firebaseInit";

const AccountStartStopRequests = () => {
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  const notify = () => toast(<ToastDisplay />);

  function ToastDisplay() {
    return (
      <div>
        <b>{notification?.title}</b>

        <p>{notification?.body}</p>
      </div>
    );
  }

  // get account request list
  const getAccountRequests = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(
        `${Apiurl.accountStopRequest}/list?page=1&limit=10&search=`
      );
      setCount(response.data.total);
      setRequests(response.data.data);
      setTotalPages(response.data.total_page);
      setIsLoading(false);
    } catch (error) {
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleUpdateStatus = async (e, id, terminalId) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await putMethod(
        `${Apiurl.manageStatus}/${terminalId}/${id}`
      );
      showAlert(response.data.data.message, "success");
      getAccountRequests();
      setIsLoading(false);
    } catch (error) {
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  onMessageListener()
    ?.then((payload) => {
      console.log("payload===>", payload);
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      localStorage.setItem(
        "reference_code",
        payload?.data?.reference_code ? payload?.data?.reference_code : ""
      );
      getAccountRequests();
    })
    ?.catch((err) => console.log("failed: ", err));

  async function handleRegistration() {
    const registration = await registerAndRequestPermission();
    if (registration) {
      const token = await getTokenData(registration);
      console.log("FCM token:=> ", token);
      if (token !== null) {
        sendFCMToken(token);
      }
    }
  }

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  useEffect(() => {
    handleRegistration();
  }, []);

  useEffect(() => {
    getAccountRequests();
  }, [currentPage]);

  const sendFCMToken = async (token) => {
    try {
      const response = await putMethod(Apiurl.fcm_token, {
        firebase_token: token,
      });
    } catch (error) {
      showAlert(error.meaasge, "error");
    }
  };

  return (
    <div>
      <Navbar />

      <div id="main-content">
        <ToastDisplay />
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>
                  Account Stop Requests:{" "}
                  <span style={{ color: "red" }}>{count}</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : requests.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Account Number</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requests.map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{i + 1}</td>
                              <td>{list?.terminal_id}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  onClick={(e) =>
                                    handleUpdateStatus(
                                      e,
                                      list.id,
                                      list.terminal_id
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountStartStopRequests;
