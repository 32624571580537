import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import Pagination from "../../../services/Pagination";
import { Modal, ModalHeader } from "reactstrap";
import { Apiurl } from "../../../services/apiRoute";
import { getMethod, postMethod, putMethod } from "../../../services/base_service";
import { showAlert } from "../../../services/alert_service";
import debounce from "lodash/debounce";

const PasswordRequests = () => {
  const {
    register,
    handleSubmit,

    reset,
    formState: { errors },
  } = useForm();
  const [count, setCount] = useState(0);
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [terminalId,setTerminalId] = useState("");
  const [pwd,setPwd] = useState("");
  const [terminal,setTerminal] = useState("");

  const getAccountList = async (search) => {
    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.user_account_list}/?page=${currentPage}&limit=${10}&search=${search}`);
      setCount(response.data.total);
      setIsLoading(false);
      setRequests(response.data.data);
      console.log("data----",response, response.data.data);
      setTotalPages(response.data.total_page);
    } catch (error) {
      setIsLoading(false);
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const setEditModal = (id,list) => {
    console.log("tid",id);
    setModal(true);;
    setTerminalId(id);
    setPwd(list.t_password);
    setTerminal(list.terminal_read_only_password);
  };

  const handleUpdatePassword = async () => {
    try {
      const response = await putMethod(`${Apiurl.update_password}/${terminalId}`,{
        "t_password": pwd,
        "terminal_read_only_password": terminal
      });
      if(response){
        setModal(false);
      }
    } catch (error) {
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  const delayedSearch = debounce(() => {
    console.log(searchQuery);
    setIsLoading(true);
    getAccountList(searchQuery);
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  useEffect(() => {
    getAccountList("");
  }, [currentPage]);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12 text-center">
                  <h1 style={{ width: "270px" }}>Update Password Request</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(() => handleUpdatePassword())}>
                      <form className=""> 
                        <div class="form-group">
                        <div class="row justify-content-between ">
                          <div class="col-25">
                            <label for="lname" style={{marginLeft:'50px'}} >T PASSWORD</label>
                          </div>
                          <div class="col-75">
                            <input
                              type="text"
                              name="T PASSWORD"
                              placeholder="T PASSWORD"
                              style={{marginLeft:'90px'}}
                              value={pwd}
                              onChange={(e) => {setPwd(e.target.value)}}
                            />
                            
                          </div>
                        </div>
                        </div>
                        <div class="row justify-content-between mb-2">
                          <div class="col-25">
                            <label for="lname" style={{marginLeft:'50px'}}>TERMINAL ROP  </label>
                          </div>
                          <div class="col-75">
                            <input
                              type="text"
                              name="TERMINAL ROP"
                              placeholder="TERMINAL ROP"
                              value={terminal}
                              onChange={(e) => {setTerminal(e.target.value)}}
                            />
                          </div>
                        </div>
                      </form>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10 d-flex justify-content-between">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "20px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                              setPwd("")
                              setTerminal("")
                              reset();
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>
                  Account List: <span style={{ color: "red" }}>{count}</span>
                </h1>
              </div>
            </div>
          </div>
          <form>
            <div className="input-group">
              <span style={{ marginRight: "10px", marginTop: "5px" }}>
                Search :{" "}
              </span>
              <input
                type="text"
                name="search"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1);
                }}
                className="form-control"
                placeholder="Account ID..."
                style={{ maxWidth: "20%" }}

              />
              <div className="input-group-append">
                <span className="input-group-text" id="search-mail">
                  <i className="fa fa-search"></i>
                </span>
              </div>
            </div>


          </form>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : requests.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Account Number</th>
                          <th>Username</th>
                          <th>T Password</th>
                          <th>Terminal ROP</th>
                          <th>USD</th>
                          <th>AUD</th>
                          <th>EUR</th>
                          <th>Action</th>
                          {/* <th>Type</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {requests.map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{i + 1}</td>
                              <td>{list?.terminal_id}</td>
                              <td>{list?.user.user_name}</td>
                              {/* <td>{list?.type}</td> */}
                              <td>
                                {list?.t_password === null
                                  ? "-"
                                  : list?.t_password}
                              </td>
                              <td>{list?.user?.user_name}</td>
                              <td>
                                {list?.user_currencies?.length === 0
                                  ? 0
                                  : list?.user_currencies?.find(
                                      (c) => c.currency.name === "USD"
                                    )?.count
                                  ? ` ${
                                      list?.user_currencies.find(
                                        (c) => c.currency.name === "USD"
                                      )?.count
                                    }`
                                  : ""}
                              </td>
                              <td>
                                {list?.user_currencies?.length === 0
                                  ? 0
                                  : list?.user_currencies?.find(
                                      (c) => c.currency.name === "AUD"
                                    )?.count
                                  ? ` ${
                                      list?.user_currencies.find(
                                        (c) => c.currency.name === "AUD"
                                      )?.count
                                    }`
                                  : ""}
                              </td>
                              <td>
                                {list?.user_currencies?.length === 0
                                  ? 0
                                  : list?.user_currencies?.find(
                                      (c) => c.currency.name === "EUR"
                                    )?.count
                                  ? ` ${
                                      list?.user_currencies.find(
                                        (c) => c.currency.name === "EUR"
                                      )?.count
                                    }`
                                  : ""}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-default btn-sm"
                                  title="Edit"
                                  onClick={() => setEditModal(list.terminal_id,list)}
                                >
                                  <i className="fa fa-pencil"></i>
                                </button>
                              </td>
                              {/* <td>{list?.user?.usd}</td> */}
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRequests;
